export default function register() {
   if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(registration => {
         registration.unregister();
      });
   }
}

export function unregister() {
   if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(registration => {
         registration.unregister();
      });
   }
}
